* {
  margin: 0;
  padding: 0;
}
.app {
  padding: 40px;
}

#nav {
  list-style: none;
  margin: 20px 0;
}

.ant-tabs-nav-wrap {
  z-index: 1000;
  background: white !important;
  position: fixed !important;
  width: 100%;
}

#content {
  margin: 30px 0 0 0;
}
